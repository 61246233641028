import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Accordion as StyledAccordion,
    Item as StyledItem,
    Header,
    Button,
    Title,
    Content,
    Icon,
} from './styles';
import Paragraph from '../Paragraph';

const Item = ({title, content, index}) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <StyledItem>
            <Header active={isActive}>
                <Button
                    role="button"
                    aria-expanded={isActive}
                    aria-controls={`accordion${index}`}
                    active={isActive}
                    onClick={() => setIsActive(!isActive)}
                    id={`accordiontitle${index}`}>
                    <Title>{title}</Title>
                    <Icon active={isActive} />
                </Button>
            </Header>
            <Content
                role="region"
                id={`accordion${index}`}
                aria-labelledby={`accordiontitle${index}`}
                active={isActive}>
                <Paragraph size="small">{content}</Paragraph>
            </Content>
        </StyledItem>
    );
};

Item.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    active: PropTypes.bool,
    index: PropTypes.number,
    clickCallback: PropTypes.func,
};

Item.defaultProps = {
    active: false,
};

export const Accordion = ({items}) => {
    if (!items) {
        return null;
    }

    const accordionItems = items.map((item, index) => (
        <Item
            title={item.title}
            content={item.content}
            key={index}
            index={index}
        />
    ));

    return <StyledAccordion>{accordionItems}</StyledAccordion>;
};

Accordion.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.string,
        })
    ),
};

Accordion.defaultProps = {};

export default Accordion;
