import React from 'react';
import Layout from '../ui/layout/Layout';
import {Section, Container} from '../ui/layout/Grid';
import Headline from '../ui/components/Headline';
import Paragraph from '../ui/components/Paragraph';
import {ReactComponent as SupportSvg} from '../assets/images/support/img-intro--kontakt.svg';
import Accordion from '../ui/components/Accordion';
import lang from '../lang/pages/support.lang';
import ConnectedContactForm from '../ui/domains/contact/ContactForm';

const Support = () => {
    return (
        <Layout>
            <Section>
                <Container width="narrow" className="text-center">
                    <Headline type="h1">{lang.hilfeUndSupport}</Headline>
                    <Paragraph>{lang.paragraph1}</Paragraph>
                    <SupportSvg />
                    <Headline type="h4">{lang.haeufigGestellteFragen}</Headline>
                    <Paragraph size="small">{lang.paragraph2}</Paragraph>
                </Container>
                <Container width="narrow">
                    <Accordion items={lang.faq} />
                </Container>
            </Section>
            <ConnectedContactForm theme="blue" />
        </Layout>
    );
};

export default Support;
