import styled from 'styled-components';
import {color, font} from '../../../styles/variables';

export const Accordion = styled.div``;

export const Item = styled.div``;

export const Title = styled.span``;

export const Header = styled.h3`
    margin: 0 0 0.5rem 0;
`;

export const Button = styled.button`
    position: relative;
    display: block;
    padding: 1rem 3rem 1rem 1rem;
    border: 0;
    font-size: 1rem;
    width: 100%;
    text-align: left;
    background-color: ${(props) =>
        props.active ? `${color.red}` : `${color.lightBlue}`};
    font-weight: ${font.weight.bold};
    color: ${(props) => (props.active ? `${color.white}` : `${color.black}`)};
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    &:focus {
        outline: 1px dotted ${color.red};
    }
`;

export const Icon = styled.span`
    display: block;
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%);

    &:before,
    &:after {
        content: '';
        width: 1rem;
        height: 2px;
        background-color: ${(props) =>
            props.active ? `${color.white}` : `${color.black}`};
        position: absolute;
        transition: transform 0.2s ease-in-out;
    }

    &:before {
        top: 50%;
        margin-top: -1px;
    }

    &:after {
        top: 50%;
        margin-top: -1px;
        transform-origin: 50% 50%;
        transform: ${(props) =>
            props.active ? `rotate(0deg)` : `rotate(90deg)`};
    }
`;

export const Content = styled.div`
    margin: 1rem 0;
    display: ${(props) => (props.active ? `block` : `none`)};
`;
