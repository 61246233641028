import React, {useEffect} from 'react';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import Form, {Input, Textarea, Select} from '../../../components/Form';
import {Container, Row, Column, Section} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import Button from '../../../components/Button';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import formLang from '../../../../lang/forms.lang';
import supportLang from '../../../../lang/pages/support.lang';

import {
    messageValidator,
    emailValidator,
    firstNameValidator,
    lastNameValidator,
    salutationValidator,
} from '../../../../utils/form-validation';

import {
    submitForm,
    selectIsFormSendPending,
    selectIsFormSendSuccess,
} from '../../../../model/contact';

const validationSchema = yup.object().shape({
    salutation: salutationValidator,
    firstName: firstNameValidator,
    lastName: lastNameValidator(true),
    email: emailValidator,
    message: messageValidator,
});

const defaultValues = {
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
    message: '',
};

export function ContactForm({theme, onSubmit, isLoading, isSuccess}) {
    const {control, handleSubmit, errors, reset} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const tranformDataForSubmit = (data) => {
        const tranformedData = {
            ...data,
            salutation: data.salutation.value,
        };
        onSubmit(tranformedData);
    };

    useEffect(() => {
        if (isSuccess) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <Section theme={theme}>
            <Form onSubmit={handleSubmit(tranformDataForSubmit)}>
                <Container width="narrow">
                    <Row>
                        <Column xs={12}>
                            <Headline type="h4" align="center">
                                {supportLang.contactForm.headline}
                            </Headline>
                            <Paragraph align="center">
                                {supportLang.contactForm.intro}
                            </Paragraph>
                        </Column>
                        <Column md={6}>
                            <Select
                                name={formLang.salutation.name}
                                label={formLang.salutation.label}
                                sectionTheme={theme}
                                options={formLang.salutation.options}
                                control={control}
                                errors={errors}
                            />
                        </Column>
                        <Column md={6}>
                            <Input
                                name={formLang.email.name}
                                placeholder={formLang.email.placeholder}
                                label={formLang.email.label}
                                theme={theme}
                                control={control}
                                errors={errors}
                            />
                        </Column>
                        <Column md={6}>
                            <Input
                                name={formLang.firstName.name}
                                placeholder={formLang.firstName.placeholder}
                                label={formLang.firstName.label}
                                theme={theme}
                                control={control}
                                errors={errors}
                            />
                        </Column>
                        <Column md={6}>
                            <Input
                                name={formLang.lastName.name}
                                placeholder={formLang.lastName.placeholder}
                                label={formLang.lastName.label}
                                theme={theme}
                                control={control}
                                errors={errors}
                            />
                        </Column>
                        <Column xs={12}>
                            <Textarea
                                name={formLang.message.name}
                                placeholder={formLang.message.placeholder}
                                label={formLang.label}
                                theme={theme}
                                control={control}
                                errors={errors}
                            />
                        </Column>
                        <Column xs={12} className="ap-padding--top">
                            <Button
                                disabled={isLoading}
                                type="submit"
                                label={supportLang.contactForm.submitButton}
                            />
                        </Column>
                    </Row>
                </Container>
            </Form>
        </Section>
    );
}

ContactForm.propTypes = {
    theme: PropTypes.oneOf(sectionThemeNames),
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
};

ContactForm.defaultProps = {
    theme: 'default',
    onSubmit: () => {},
    isLoading: false,
};

const ConnectedContactForm = (props) => {
    const dispatch = useDispatch();
    const pendingRequests = useSelector(selectIsFormSendPending);
    const isSelectPendingRequestsSuccess = useSelector(selectIsFormSendSuccess);
    const handleSubmit = (data) => {
        dispatch(submitForm(data));
    };

    return (
        <ContactForm
            {...props}
            onSubmit={handleSubmit}
            isLoading={pendingRequests}
            isSuccess={isSelectPendingRequestsSuccess}
        />
    );
};

export default ConnectedContactForm;
