import React from 'react';

export default {
    hilfeUndSupport: (
        <i>
            Hilfe und <strong>Support</strong>
        </i>
    ),
    paragraph1:
        'Wenn Du offene Fragen oder andere Anliegen klären möchtest, bist Du hier richtig. Schau einfach in unsere FAQ oder schreibe uns eine Nachricht.',
    haeufigGestellteFragen: <strong>Häufig gestellte Fragen</strong>,
    paragraph2:
        'Bestimmte Informationen sind für Deinen Geschmack zu knapp ausgefallen? Hier haben wir die häufigsten Fragen für Dich zusammengefasst.',
    faq: [
        {
            title: 'Wie kann ich Punkte sammeln?',
            content:
                'Punkte kannst Du sammeln, indem Du Trainings absolvierst. Wenn Du ein Training bestehst, erhältst Du 50 Punkte. Darüber hinaus bringt Dir der erfolgreiche Abschluss unserer Aktionen und Präsenzschulungen in der Offizin durch unseren Außendienst üppige Extrapunkte ein.',
        },
        {
            title: 'Wie kann ich meine Trainingskollektion zusammenstellen?',
            content:
                'Du kannst Deine Trainingskollektion auf der Startseite einstellen. Wähle dazu einfach aus, ob Du Dich für Produktinformationen, Beratung oder den Apothekenalltag interessierst und ob Du PTA, PKA, Apotheker/in oder Auszubildende/r bist. Wenn Du Deine Interessen anpassen möchtest, kannst Du dies jederzeit unter „Deine Daten" vornehmen.',
        },
        {
            title:
                'Wie viele Fragen muss ich richtig beantworten, um ein Training zu bestehen?',
            content:
                'Bei Trainings mit bis zu 5 Fragen kannst Du eine Frage falsch beantworten und trotzdem bestehen. Bei Trainings mit mehr als 5 Fragen kannst Du zwei Fragen falsch beantworten und trotzdem bestehen.',
        },
        {
            title: 'Wo kann ich meinen Punktestand einsehen?',
            content:
                'Wenn Du eingeloggt bist, kannst Du Deinen Punktestand oben rechts unterhalb Deines Usernamens in der blauen Leiste sehen. Unter "Dein Team" findest Du darüber hinaus auch den Punktestand Deiner Kollegen/innen, die gemeinsam erzielten Punkte für eure Apotheke und die Platzierung im Vergleich zu anderen Apotheken. Unter "Dein Team" kannst Du auch Deine Punktehistorie einsehen, um nachzuvollziehen, wann Du wofür Punkte erhalten hast und wann Du welche ausgegeben hast.',
        },
        {
            title: 'Wie kann ich meine Punkte gegen eine Prämie einlösen?',
            content:
                'Deine Punkte kannst Du im Prämienshop einlösen. Du hast die Möglichkeit zwischen einer Sachprämie und einem Gutschein von vouchermee auszuwählen. Den Gutschein kannst Du dann gegen einen Gutschein eines Anbieters Deiner Wahl eintauschen.',
        },
        {
            title: 'Wie erhalte ich meine Prämie?',
            content:
                'Du kannst Deine Punkte gegen Sachprämien oder Gutscheine einlösen. Deine ausgewählte Prämie erhältst Du innerhalb einiger Tage per Post an Deine Apotheke zugeschickt. Gutscheine erhältst Du innerhalb weniger Minuten an Deine E-Mail-Adresse.',
        },
        {
            title: 'Welche Trainings gibt es?',
            content:
                'Unsere Trainingskollektion ist breit gefächert: Du kannst viel über die Produkte und deren Indikationen lernen, Dir Tipps für die Kundenberatung einholen und Dir außerdem Wissen über den Apothekenalltag aneignen, wie z. B. zur Schaufensterdekoration oder kaufmännischen Aspekten in der Apotheke. Jeden Monat kommen neue Trainings dazu. Welche Trainings Dich interessieren, kannst Du in Deinen Einstellungen festlegen. So erhältst Du ein auf Dich zugeschnittenes Trainings-Angebot.',
        },
        {
            title: 'Kann ich Trainings mehrfach durchspielen?',
            content:
                'Du kannst die Trainings so oft wiederholen wie Du willst, auch wenn Du sie schon bestanden hast – allerdings erhältst Du nur einmal Punkte. Wenn Du ein Training mal nicht bestehen solltest, ist das kein Problem. Beim nächsten Mal schaffst Du es bestimmt. Deine Versuche sind unbegrenzt.',
        },
        {
            title: 'Mein Training läuft nicht richtig. Was kann ich tun?',
            content:
                'Wenn Du Probleme bei der Darstellung oder der Funktion eines Trainings hast, hilft es häufig, den Browser zu wechseln oder zu aktualisieren oder einen Computer mit Desktop zu verwenden. Falls es dennoch Probleme gibt, schreibe uns bitte eine Nachricht über das Kontaktformular, damit wir das Training korrigieren können.',
        },
        {
            title: 'Kann ich während den Trainings Pausen machen?',
            content:
                'Du kannst das Training jederzeit abbrechen, startest dann allerdings beim nächsten Mal von vorne.',
        },
        {
            title: 'Was sind Aktionen?',
            content:
                'Aktionen bieten Dir die Möglichkeit bei Apotique Extrapunkte zu sammeln. Aktionen können zum Beispiel die Herausforderung sein, Dein Apothekenschaufenster neu zu dekorieren und uns ein Foto einzusenden. Wir schauen uns Dein Foto an und Du erhältst Punkte auf Dein Apotique Punktekonto gutgeschrieben.',
        },
        {
            title: 'Wo finde ich meine Pohl-Boskamp-Kundennummer?',
            content:
                'Deine Apotheke sollte eine BGA-Nummer und eine Pohl-Boskamp-Kundennummer haben. Die Kundennummer findest Du z. B. auf allen Rechnungen von Pohl-Boskamp oder in den Pohl-Boskamp-Kontaktdaten in eurem Computersystem. Falls Du die Nummer nicht herausfinden kannst, kannst Du Dich gerne über das Kontaktformular bei uns melden. Wir helfen Dir gerne weiter.',
        },
        {
            title: 'Wie kann ich meine persönlichen Daten ändern?',
            content:
                'Um Deine persönlichen Daten zu ändern, klickst Du im Menü auf Deinen Usernamen und dann auf „Deine Daten". Schon erscheint ein Formular, in dem Du Deine persönlichen Angaben, Deine Zugangsdaten zu Apotique, Deine Apothekenzugehörigkeit und Deine Training-Interessen anpassen kannst. Vergiss nicht auf "Änderungen speichern" zu klicken, damit die Änderung nicht verloren geht.',
        },
        {
            title: 'Wie kann ich meinen Account löschen? ',
            content:
                'Wenn Du Deinen Account bei Apotique löschen möchtest, schreibe uns eine kurze Mail an kontakt@apotique.de oder nutze das Kontaktformular. Wir kümmern uns dann um die Löschung Deines Accounts. Wir würden uns freuen, wenn Du uns auch den Grund für die Löschung mitteilen würdest, damit wir Apotique noch besser machen können.',
        },
    ],
    contactForm: {
        headline: (
            <>
                Wir helfen{' '}
                <strong>
                    <i>Dir gerne weiter!</i>
                </strong>
            </>
        ),
        intro: (
            <>
                Bei Fragen und Anregungen kannst Du uns gerne eine Nachricht
                schreiben – wir antworten Dir so schnell wie möglich.
            </>
        ),
        submitButton: 'Nachricht senden',
    },
};
